import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import Rsa from "./66440687a73beb62.js"
import qs from 'qs'
import router from '@/router'
// 创建 axios 实例
const request = axios.create({
	// API 请求的默认前缀
	baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : '/',
	timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
	if (error.response) {
		const data = error.response.data;
		// 从 localstorage 获取 token
		const token = storage.get(ACCESS_TOKEN);
		// if (error.response.status === 403) {
		// 	notification.error({
		// 		message: 'Forbidden',
		// 		description: data.message
		// 	})
		// }
		if (error.response.status === 401||error.response.status === 403) {
			notification.error({
				message: '登录已过期',
				description: '请重新登录'
			})
			if (token) {
				store.dispatch('Logout').then(() => {
					setTimeout(() => {
						window.location.reload()
					}, 1500)
				})
			}
		}
	}
	return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
	const token = storage.get(ACCESS_TOKEN)
	// 如果 token 存在
	// 让每个请求携带自定义 token 请根据实际情况自行修改
	config.headers[ "X-Requested-With" ] = "XMLHttpRequest"
	if (token) {
		config.headers['token'] = token
	}
	//console.log('config',config);
	if (config.method == "post") {
		config.data = qs.stringify(config.data);
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
	}
	return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
	const token = storage.get(ACCESS_TOKEN);
	if(response.data.code=='2089'){
		notification.error({
			message: '登录已过期',
			description: '请重新登录'
		})
        const token = storage.get(ACCESS_TOKEN);
		if(token){
            store.dispatch('Logout').then(() => {
                setTimeout(() => {
                    window.location.reload()
                }, 0)
            })
        }
	}
    let responseData = response.data;
    //console.log('responseData',responseData);
    if(responseData.data){
        if(Object.prototype.toString.call(response.data.data) === '[object Object]'||Object.prototype.toString.call(response.data.data) === '[object Array]'){
            return responseData
        }else{
            responseData.data = Rsa.decrypt(responseData.data);   
            responseData.data = responseData.data.toString().replace(new RegExp("\\\\\"","gm"));  
            //console.log('responseData1',responseData);
            responseData.data = JSON.parse(responseData.data); 
            return responseData
            
        }
    }else{
        return responseData
    }
}, errorHandler)

const installer = {
	vm: {},
	install(Vue) {
		Vue.use(VueAxios, request)
	}
}

export default request

export {
	installer as VueAxios,
	request as axios
}
