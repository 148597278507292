import request from '@/utils/request'

const isProd = process.env.NODE_ENV === 'production'

console.log('isProd',isProd)

const userApi = {
  Login: (isProd?'':'/api')+'/newAdmin.php?c=Login&a=loginActNew2',
  Logout: (isProd?'':'/api')+'/newAdmin.php?c=Login&a=logoutAct',
  // ForgePassword:(isProd?'':'/api')+ '/auth/forge-password',
  // Register: (isProd?'':'/api')+'/auth/register',
  // twoStepCode:(isProd?'':'/api')+ '/auth/2step-code',
  // SendSms: (isProd?'':'/api')+'/account/sms',
  // SendSmsErr:(isProd?'':'/api')+ '/account/sms_err',
  // get my info
  UserInfo: (isProd?'':'/api')+'/newAdmin.php?c=Login&a=userinfo',
  UserMenu: (isProd?'':'/api')+'/newAdmin.php?c=Login&a=my_menu_list',
  getCode:(isProd?'':'/api')+'newAdmin.php?c=Login&a=varify_code',
  changePwd:(isProd?'':'/api')+'/newAdmin.php?c=user&a=changePassword',
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}


export function changePwd (parameter) {
  return request({
    url: userApi.changePwd,
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha (parameter) {
  return request({
    url: userApi.SendSms,
    method: 'post',
    data: parameter
  })
}



export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav () {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * get user 2step code open?
 * @param parameter {*}
 */
export function get2step (parameter) {
  return request({
    url: userApi.twoStepCode,
    method: 'post',
    data: parameter
  })
}

