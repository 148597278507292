// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'


import system from '@/utils/commonName.js'
Vue.prototype.$sys = system;

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission'   // permission control
import './utils/filter' // global filter
import './global.less'
import { Icon } from 'ant-design-vue'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
    tapToClose: false,
    zoomEl: true
};
Vue.use(preview, options)
Vue.use(preview)


import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

import VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)

Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
Vue.component('pro-layout', ProLayout)
Vue.component('page-header-wrapper', PageHeaderWrapper)

const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_SVG_URL
})
Vue.component('my-icon', MyIcon)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
    router,
    store,
    i18n,
    created: bootstrap,
    render: h => h(App)
}).$mount('#app')

