// eslint-disable-next-line
import {
	UserLayout,
	BasicLayout,
	BlankLayout
} from '@/layouts'
import {
	bxAnaalyse,
	service
} from '@/core/icons'

const RouteView = {
	name: 'RouteView',
	render: (h) => h('router-view')
}

export const asyncRouterMap = [
	{
		path: '/',
		name: 'index',
		component: BasicLayout,
		meta: {
			title: '主页'
		},
		redirect: '/dashboard/analysis',
		children: [
			
		]
	}
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [{
		path: '/user',
		component: UserLayout,
		redirect: '/user/login',
		hidden: true,
		children: [{
				path: 'login',
				name: 'login',
				component: () => import('@/views/user/Login')
			},
			// {
			// 	path: 'register',
			// 	name: 'register',
			// 	component: () => import('@/views/user/Register')
			// },
			// {
			// 	path: 'register-result',
			// 	name: 'registerResult',
			// 	component: () => import('@/views/user/RegisterResult')
			// },
			// {
			// 	path: 'recover',
			// 	name: 'recover',
			// 	component: undefined
			// }
            
		]
	},
	{
		path: '/404',
		component: () => import('@/views/exception/404')
	}
]
