<template>
	<div>
		<a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
			<span class="ant-pro-account-avatar">
				<a-avatar size="small" src="https://wapi.hfwater.cn/public/images/tx.png" class="antd-pro-global-header-index-avatar" />
				<span>{{ currentUser.name }}</span>
			</span>
			<template v-slot:overlay>
				<a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
					<!-- <a-menu-item v-if="menu" key="center" @click="handleToCenter">
		      <a-icon type="user" />
		      个人中心
		    </a-menu-item>
		    <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
		      <a-icon type="setting" />
		      个人设置
		    </a-menu-item>
		    <a-menu-divider v-if="menu" /> -->
					<a-menu-item key="password" @click="handleToSettings">
					  <a-icon type="setting" />
					  修改密码
					</a-menu-item>
					<a-menu-divider v-if="menu" />
					<a-menu-item key="logout" @click="handleLogout">
						<a-icon type="logout" />
						退出登录
					</a-menu-item>
				</a-menu>
			</template>
		</a-dropdown>
		<span v-else>
			<a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
		</span>
		<a-modal class="modal_foot_center"
		      title="修改密码"
			  centered
			  :closable="false"
			  :maskClosable="false"
			  :footer="null"
		      :visible="visible"
			  width="420px"
		    >
		    <div>
				<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" @submit="handleSubmit">
				    <a-form-item label="原密码">
						<a-input
							type="password" v-decorator="['oldPassword', { rules: [{ required: true, message: '请输入原密码' }] }]" placeholder="请输入原密码"
						/>
				    </a-form-item>
					<a-form-item label="新密码">
						<a-input
							type="password" v-decorator="['newPassword1', { rules: [{ required: true, message: '请输入新密码' }] }]"  placeholder="请输入新密码"
						/>
					</a-form-item>
					<a-form-item label="新密码">
						<a-input
							type="password" v-decorator="['newPassword2', { rules: [{ required: true, message: '请再次输入新密码' }] }]"  placeholder="请再次输入新密码"
						/>
					</a-form-item>
					<a-form-item :wrapper-col="{ span: 24, offset: 0 }" style="text-align: center;">
						<a-button type="" @click="handleCancel">取消</a-button>
					    <a-button type="primary" html-type="submit" style="margin-left: 20px;">确定</a-button>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import qs from 'qs';
	import axios from 'axios'
	const isProd = process.env.NODE_ENV === 'production'
	import storage from 'store'
	import { ACCESS_TOKEN } from '@/store/mutation-types'
	
	
	import {
		Modal
	} from 'ant-design-vue'
	
	export default {
		name: 'AvatarDropdown',
		props: {
			currentUser: {
				type: Object,
				default: () => null
			},
			menu: {
				type: Boolean,
				default: true
			}
		},
		data(){
			return {
				visible:false,
				formLayout: 'horizontal',
				form: this.$form.createForm(this, { name: 'coordinated' }),
			}
		},
		methods: {
			handleSubmit(e){
				const token = storage.get(ACCESS_TOKEN)
				console.log('token=',token);
				e.preventDefault();
				this.form.validateFields((err, values) => {
					//console.log('values',values);
				    if (!err) {
				        console.log('Received values of form: ', values);
				    }
					
					if(values.newPassword1!=values.newPassword2){
						this.$message.error('2次密码不一致');
						return
					}
					let params = {
						old_password:values.oldPassword,
						password:values.newPassword1
					}
					axios({
						method: "POST",
						url:(process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : '')+(isProd?'':'/api')+'/newAdmin.php?c=user&a=changePassword',
						data:qs.stringify(params),
						headers: {
							'token': token
						}
					}).then(res => {
						//console.log('res',res)
						if(res.data.code==200){
							this.$message.success(res.data.msg)
							localStorage.clear();
							setTimeout(_=>{
								location.reload();
							},600)
							//this.data = res.data.data
						}else{
							this.$message.error(res.data.msg)
						}
					}).catch(res=>{
						this.$message.error(res.data.msg)
					})
				});
			},
			handleCancel(){
				this.visible = false;
			},
			handleToCenter() {
				this.$router.push({
					path: '/account/center'
				})
			},
			handleToSettings() {
				this.visible = true;
				return
				this.$router.push({
					path: '/account/settings'
				})
			},
			handleLogout(e) {
				Modal.confirm({
					content: this.$t('确认退出吗?'),
					onOk: () => {
						return this.$store.dispatch('Logout').then(() => {
							this.$router.push({
								name: 'login'
							})
						})
					},
					onCancel() {}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.ant-pro-drop-down {
		/deep/ .action {
			margin-right: 8px;
		}

		/deep/ .ant-dropdown-menu-item {
			min-width: 160px;
		}
	}
</style>
