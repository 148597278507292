import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import notification from 'ant-design-vue/es/notification'
import {
	setDocumentTitle,
	domTitle
} from '@/utils/domUtil'
import {
	ACCESS_TOKEN
} from '@/store/mutation-types'
import {
	i18nRender
} from '@/locales'

// NProgress Configuration
NProgress.configure({
	showSpinner: false
}) 

const whiteList = ['login', 'register', 'registerResult'] // no redirect whitelist
const loginRoutePath = '/user/login'
const defaultRoutePath = 'dashboard/analysis'



router.beforeEach((to, from, next) => {
	NProgress.start()
	to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${domTitle}`))
	/* has token */
	if (storage.get(ACCESS_TOKEN)) {
		if (to.path === loginRoutePath) {
			next({
				path: defaultRoutePath
			})
			NProgress.done()
		} else {
			//console.log('store.getters.roles=',store.getters.roles)
			if (store.getters.roles.length === 0) {
				store
					.dispatch('GetInfo')
					.then(res => {
						const roles = res.data
						store.dispatch('GenerateRoutes', {
							roles
						}).then(() => {
							router.addRoutes(store.getters.addRouters)
							// 请求带有 redirect 重定向时，登录自动重定向到该地址
							const redirect = decodeURIComponent(from.query.redirect || to.path)
							if (to.path === redirect) {
								next({ ...to,
									replace: true
								})
							} else {
								// 跳转到目的路由
								next({
									path: redirect
								})
							}
						})
					})
					.catch((res) => {
						console.log('res',res)
						notification.error({
							message: '提示',
							description: res.msg
						})
						store.dispatch('Logout').then(() => {
							next({
								path: loginRoutePath,
								query: {
									redirect: to.fullPath
								}
							})
						})
					})
			} else {
				store.dispatch('GetInfo').then(res =>{
					//console.log('get info success',res)
					if(res.code==200){
						next()
					}else{
						notification.error({
							message: '提示',
							description: res.msg
						})
						store.dispatch('Logout').then(() => {
							next({
								path: loginRoutePath,
								query: {
									redirect: to.fullPath
								}
							})
						})
					}
				}).catch(res =>{
					console.log('get info fail',res)
					notification.error({
						message: '提示',
						description: res.msg
					})
					store.dispatch('Logout').then(() => {
						next({
							path: loginRoutePath,
							query: {
								redirect: to.fullPath
							}
						})
					})
				})
				
			}
		}
	} else {
		if (whiteList.includes(to.name)) {
			// 在免登录白名单，直接进入
			next()
		} else {
			next({
				path: loginRoutePath,
				query: {
					redirect: to.fullPath
				}
			})
			NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
		}
	}
})

router.afterEach(() => {
	NProgress.done() // finish progress bar
})
