<template>
	<div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
		<!-- <div class="container">
			<div class="top">
				<div class="header">
					<a href="/">
						<img src="~@/assets/logo.svg" class="logo" alt="logo">
						<span class="title">Ant Design</span>
					</a>
				</div>
				<div class="desc">
					Ant Design 是西湖区最具影响力的 Web 设计规范
				</div>
			</div>

			<router-view />

			<div class="footer">
				<div class="links">
					<a href="_self">帮助</a>
					<a href="_self">隐私</a>
					<a href="_self">条款</a>
				</div>
				<div class="copyright">
					Copyright &copy; 2018 vueComponent
				</div>
			</div>
		</div> -->
		<div class="contain">
			<router-view />
			<!-- <div class="center_form">
				<div class="cf_left">
					
				</div>
				<div class="cf_right">
					<i class="login_form_back"></i>
					<div>扫码登录</div>
					
				</div>
			</div> -->
			<div class="login_back"></div>
		</div>
	</div>
</template>

<script>
	import {
		deviceMixin
	} from '@/store/device-mixin'

	export default {
		name: 'UserLayout',
		mixins: [deviceMixin],
		mounted() {
			document.body.classList.add('userLayout')
		},
		beforeDestroy() {
			document.body.classList.remove('userLayout')
		}
	}
</script>

<style lang="less" scoped>
	#userLayout.user-layout-wrapper {
		height: 100%;

		&.mobile {
			.container {
				.main {
					max-width: 368px;
					width: 98%;
				}
			}
		}
		
		.contain{
			width: 100%;
			min-height: 100%;
			background-image: linear-gradient(173deg, #CDEAFF 0%, #9BCEFF 100%);
			position: relative;
			.login_back{
				width: 100%;
				height: 309px;
				background: url(~@/assets/images/login_back.png) no-repeat;
				background-size: 100% 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				z-index:1;
				animation:cloud 40s linear infinite;
			}
			@keyframes cloud {
				from{background-position:0% 0%}  
			    to{background-position:50% 50%}  
			}  
			.center_form{
				width: 673px;
				height: 434px;
				position: absolute;
				z-index: 2;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				display:flex;
				justify-content: space-between;
				.cf_left{
					width: 333px;
					min-width: 333px;
					height: 100%;
					background: url(~@/assets/images/login_form_left.png) no-repeat;
					background-size: 100%;
				}
				.cf_right{
					width: 340px;
					height: 100%;
					position: relative;
					background: #fff;
					.login_form_back{
						display: inline-block;
						background: url(~@/assets/images/login_form_back.png) no-repeat;
						background-size: 100%;
						width: 176px;
						height: 134px;
						position: absolute;
						top: 0;
						right: 0;
					}
					
				}
			}
		}

		.container {
			width: 100%;
			min-height: 100%;
			background: #f0f2f5 url(~@/assets/background.svg) no-repeat 50%;
			background-size: 100%;
			padding: 110px 0 144px;
			position: relative;

			a {
				text-decoration: none;
			}

			.top {
				text-align: center;

				.header {
					height: 44px;
					line-height: 44px;

					.badge {
						position: absolute;
						display: inline-block;
						line-height: 1;
						vertical-align: middle;
						margin-left: -12px;
						margin-top: -10px;
						opacity: 0.8;
					}

					.logo {
						height: 44px;
						vertical-align: top;
						margin-right: 16px;
						border-style: none;
					}

					.title {
						font-size: 33px;
						color: rgba(0, 0, 0, .85);
						font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
						font-weight: 600;
						position: relative;
						top: 2px;
					}
				}

				.desc {
					font-size: 14px;
					color: rgba(0, 0, 0, 0.45);
					margin-top: 12px;
					margin-bottom: 40px;
				}
			}

			.main {
				min-width: 260px;
				width: 368px;
				margin: 0 auto;
			}

			.footer {
				position: absolute;
				width: 100%;
				bottom: 0;
				padding: 0 16px;
				margin: 48px 0 24px;
				text-align: center;

				.links {
					margin-bottom: 8px;
					font-size: 14px;

					a {
						color: rgba(0, 0, 0, 0.45);
						transition: all 0.3s;

						&:not(:last-child) {
							margin-right: 40px;
						}
					}
				}

				.copyright {
					color: rgba(0, 0, 0, 0.45);
					font-size: 14px;
				}
			}
		}
	}
</style>
