// eslint-disable-next-line
import * as loginService from '@/api/login'
// eslint-disable-next-line
import { BasicLayout, BlankLayout, PageView, RouteView } from '@/layouts'

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  BasicLayout: BasicLayout,
  BlankLayout: BlankLayout,
  RouteView: RouteView,
  PageView: PageView,
  '403': () => import(/* webpackChunkName: "error" */ '@/views/exception/403'),
  '404': () => import(/* webpackChunkName: "error" */ '@/views/exception/404'),
  '500': () => import(/* webpackChunkName: "error" */ '@/views/exception/500'),

  // 你需要动态引入的页面组件
  'Analysis': () => import('@/views/dashboard/Analysis'),
  
  //业务办理
  'transfer_personal': () => import('@/views/service/transfer_personal'),
  'accountCancelJMH':() => import('@/views/service/accountCancelJMH'),
  'waterChangeJMH':() => import('@/views/service/waterChangeJMH'),
  'multiPopulationChangeApp':() =>import ('@/views/service/multiPopulationChangeApp'),
  'waterReconnectionJMH':() =>import ('@/views/service/waterReconnectionJMH'),
  'waterStopJMH':() =>import ('@/views/service/waterStopJMH'),
  'transfer_unit':() =>import ('@/views/service/transfer_unit'),
  'accountCancelGSH':() =>import ('@/views/service/accountCancelGSH'),
  'waterAccountChangeGSH':() =>import ('@/views/service/waterAccountChangeGSH'),
  'waterChangeGSH':() =>import ('@/views/service/waterChangeGSH'),
  'waterStopGSH':() =>import ('@/views/service/waterStopGSH'),
  'waterOut':() =>import ('@/views/service/waterOut'),
  'waterApplyGSH':() =>import ('@/views/service/waterApplyGSH'),
  'waterOutReform':() =>import ('@/views/service/waterOutReform'),
  'newCommunityWaterApply_report':() =>import ('@/views/service/newCommunityWaterApply_report'),
  'newCommunityWaterApply_design':() =>import ('@/views/service/newCommunityWaterApply_design'),
  
  'service_zjfch':() =>import ('@/views/service/service_zjfch'),
  'waterReconnectionGSH':() =>import ('@/views/service/waterReconnectionGSH'),
  'service_invoicelink':() =>import ('@/views/service/service_invoicelink'),
  'service_fzz':() =>import ('@/views/service/service_fzz'),
  'service_ztsbch':() =>import ('@/views/service/service_ztsbch'),
  'service_gtsh':() =>import ('@/views/service/service_ztsbch'),
  'service_sgys':() =>import ('@/views/service/service_sgys'),
  'service_zsys':() =>import ('@/views/service/service_zsys'),
  
  //内容中心
  'gsNewsList':() =>import ('@/views/article/gsNewsList'),
  'wqrNewsList':() =>import ('@/views/article/wqrNewsList'),
  'newsDetail':() =>import ('@/views/article/newsDetail'),
  'knowledgeList':() =>import ('@/views/article/knowledgeList'),
  'editKlArticle':() =>import ('@/views/article/editKlArticle'),
//   'outletsList':() =>import ('@/views/outlets/outletsList'),
  'noticeList':() =>import ('@/views/notice/noticeList'),
  'serviceList':() =>import ('@/views/notice/serviceList'),
  
  'downloadList':() =>import ('@/views/article/downloadList'),
  'guideList':() =>import ('@/views/article/guideList'),
  'editGuide':() =>import ('@/views/article/editGuide'),
  'editDownLoad':() =>import ('@/views/article/editDownLoad'),
  'publicity':() =>import ('@/views/article/publicity'),
  'editPublicity':() =>import ('@/views/article/editPublicity'),
  
  //客户管理
  'userList':() =>import ('@/views/userManage/userList'),
  
  //运营中心
  'answerList':() =>import ('@/views/answerManage/answerList'),
  'editAnswer':() =>import ('@/views/answerManage/editAnswer'),
  'questionBank':() =>import ('@/views/answerManage/questionBank'),
  'questionList':() =>import ('@/views/question/questionList'),
  'bannerList':() =>import ('@/views/article/bannerList'),
  'agreeList':() =>import ('@/views/agreement/agreeList'),
  'editAgree':() =>import ('@/views/agreement/editAgree'),
  'joinList':() =>import ('@/views/answerManage/joinList'),
  'rank':() =>import ('@/views/answerManage/rank'),
  
  'quesList':() =>import('@/views/questionnaire/quesList'),
  
  
  //发票申领
  'invoApplyList':() =>import('@/views/invoice/invoApplyList'),
  
  //销帐申请
  'cancelApplyList':() =>import('@/views/cancelApply/cancelApplyList'),
  
  //系统设置
  'RoleList':() => import('@/views/other/RoleList'),
  'roleEdit':() => import('@/views/other/roleEdit'),
  'sysUserList':() => import('@/views/other/UserList'),
  'userEdit':() => import('@/views/other/userEdit'),
  'PermissionList':() => import('@/views/other/PermissionList'),
  'log_login':() => import('@/views/logManage/log_login'),
  'log_op':() => import('@/views/logManage/log_op'),
  
  'reportList':() => import('@/views/report/reportList'),
  'complainList':() => import('@/views/report/complainList'),

  'prosecutionList':() => import('@/views/supervise/prosecutionList'),
  'suggestionList':() => import('@/views/supervise/suggestionList'),

  'statistics':() =>import('@/views/quesStatistics/statistics'),
  'staJoinList':() =>import('@/views/quesStatistics/joinList'),
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*', redirect: '/404', hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'index',
  path: '',
  component: 'BasicLayout',
  redirect: '/dashboard',
  meta: {
    title: '首页'
  },
  children: []
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = (token) => {
  return new Promise((resolve, reject) => {
    loginService.getCurrentUserNav(token).then(res => {
	  let defaultRoute = [	
		{name: "dashboard", parentId: 0, id: 1001, meta: {icon:'appstore',show:true,title:'仪表盘'}, component: "RouteView"},
		{name: "Analysis", parentId: 1001, id: 2, meta: {show:true,title:'分析页'}, component: "Analysis"}]
      const result = defaultRoute.concat(res.data)
      const menuNav = []
      const childrenNav = []
      //后端数据, 根级树数组,  根级 PID
      listToTree(result, childrenNav, 0)
      rootRouter.children = childrenNav
      menuNav.push(rootRouter)
      //console.log('menuNav', menuNav)
      const routers = generator(menuNav)
      routers.push(notFoundRouter)
      //console.log('routers=', routers)
      resolve(routers)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parent) => {
  return routerMap.map(item => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${parent && parent.path || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: (constantRouterComponents[item.component || item.key]) || (() => import(`@/views/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
