<template>
	<a-popover v-model="visible" trigger="click" placement="topRight" overlayClassName="header-notice-wrapper"
	 :getPopupContainer="() => $refs.noticeRef.parentElement" :autoAdjustOverflow="true" :arrowPointAtCenter="true"
	 :overlayStyle="{ width: '300px', top: '50px' }">
		<template slot="content">
			<a-spin :spinning="loading">
				<!-- <a-tabs>
					<a-tab-pane tab="通知" key="1">
						<a-list>
							<a-list-item>
								<a-list-item-meta title="您有一条新的业务办理" description="2020-07-13 12:00:00">
									<a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png" />
								</a-list-item-meta>
							</a-list-item>
							<a-list-item>
								<a-list-item-meta title="您有一条新的业务办理" description="2020-07-13 12:00:00">
									<a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png" />
								</a-list-item-meta>
							</a-list-item>
							<a-list-item>
								<a-list-item-meta title="您有一条新的业务办理" description="2020-07-13 12:00:00">
									<a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png" />
								</a-list-item-meta>
							</a-list-item>
						</a-list>
					</a-tab-pane>
					<a-tab-pane tab="消息" key="2">
						123
					</a-tab-pane>
					<a-tab-pane tab="待办" key="3">
						123
					</a-tab-pane>
				</a-tabs> -->
				<a-list>
					<a-list-item v-for="(item,index) in data" :key="index" style="cursor:pointer">
						<a-list-item-meta :title="item.name" :description="'代办件数:'+item.waitNum" @click="redirect(item.url)">
							<a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png" />
						</a-list-item-meta>
					</a-list-item>
				</a-list>
			</a-spin>
		</template>
		<span @click="fetchNotice" class="header-notice" ref="noticeRef" style="padding: 0 18px">
			<a-badge :dot="dot">
				<a-icon style="font-size: 16px; padding: 4px" type="bell" />
			</a-badge>
		</span>
	</a-popover>
</template>

<script>
	const isProd = process.env.NODE_ENV === 'production'
	import axios from 'axios'
	import storage from 'store'
	import { ACCESS_TOKEN } from '@/store/mutation-types'
	const token = storage.get(ACCESS_TOKEN)
	import moment from 'moment'
	export default {
		name: 'HeaderNotice',
		data() {
			return {
				loading: false,
				visible: false,
				data:{},
				dot:false,
				int:null,
			}
		},
		created(){
			setTimeout(()=>{
				this.getNotice();
			    this.init();
			},3000)
		},
		methods: {
			init(){
				this.init = setInterval(()=>{
					console.log('interval...');
					this.getNotice()
				},60000)
			},
			fetchNotice() {
				if (!this.visible) {
					this.loading = true
					setTimeout(() => {
						this.loading = false
					}, 500)
				} else {
					this.loading = false
				}
				this.visible = !this.visible
				this.getNotice()
			},
			getNotice(){
				axios({
					method: "POST",
					url:(process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : '')+(isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=waitbusiness',
					data:{},
					headers: {
						'token': storage.get(ACCESS_TOKEN)
					}
				}).then(res => {
					//console.log('res',res)
					if(res.data.code==200){
						let data = res.data.data||[];
                        this.data = data;
						if(data.length>0){
							this.dot = true;
						}else{
							clearInterval(this.init);
						}
					}
				})
			},
			redirect(url){
				this.$router.push({path:url})
			}
		},
		filters: {
			time(t) {
				if (t) {
					return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:mm')
				} else {
					return t
				}
			}
		},
	}
</script>

<style lang="css">
	.header-notice-wrapper {
		top: 50px !important;
	}
	.ant-popover-inner-content{
		max-height: 320px;
		overflow-y: auto;
	}
</style>
<style lang="less" scoped>
	.header-notice {
		display: inline-block;
		transition: all 0.3s;

		span {
			vertical-align: initial;
		}
	}
</style>
