import storage from 'store'
import {
	login,
	getInfo,
	logout
} from '@/api/login'
import {
	ACCESS_TOKEN
} from '@/store/mutation-types'
import {
	welcome
} from '@/utils/util'

const user = {
	state: {
		token: '',
		name: '',
		welcome: '',
		avatar: '',
		roles: [],
		info: {},
		uid:'',
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NAME: (state, {
			name,
			welcome
		}) => {
			state.name = name
			state.welcome = welcome
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		SET_INFO: (state, info) => {
			state.info = info
		},
		SET_UID: (state,uid) =>{
			state.uid = uid
		}
	},

	actions: {
		// 登录
		Login({
			commit
		}, userInfo) {
			return new Promise((resolve, reject) => {
				login(userInfo).then(response => {
					console.log('response', response)
					if(response.code==200){
						const result = response.data
						storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
						storage.set('uid', Number(result.uid), 7 * 24 * 60 * 60 * 1000)
						commit('SET_TOKEN', result.token)
						commit('SET_UID',result.uid)
						resolve(result)
					}else if(response.code == 2017){
						reject(response);
					}else{
						reject(response.msg)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 获取用户信息
		GetInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getInfo().then(response => {
					if(response.code == 200){
						const result = response.data
						if (result.permissions && result.permissions.length > 0) {
							const role = result
							//console.log('result',result);
					
							role.permissions = result.permissions
							role.permissions.map(per => {
								if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
									const action = per.actionEntitySet.map(action => {
										return action.action
									})
									per.actionList = action
								}
							})
							role.permissionList = role.permissions.map(permission => {
								return permission.permissionId
							})
							//console.log('result.role=',result.role)		
							commit('SET_ROLES', result)
							commit('SET_INFO', result.user)
						} else {
							reject(new Error('getInfo: roles must be a non-null array !'))
						}
						commit('SET_NAME', {
							name: result.user.realname,
							welcome: welcome()
						})
						commit('SET_AVATAR', result.user.avatarurl)
						storage.set('sysName', result.user.realname, 7 * 24 * 60 * 60 * 1000)
						resolve(response)
					}else{
						reject(response)
					}
				}).catch(error => {
					reject(error)
				})
			})
		},

		// 登出
		Logout({
			commit,
			state
		}) {
			return new Promise((resolve) => {
				logout(state.token).then(() => {
					resolve()
					commit('SET_TOKEN', '')
					commit('SET_ROLES', [])
					storage.remove(ACCESS_TOKEN)
					storage.remove('uid')
					storage.remove('sysName')
				})
			})
		}

	}
}

export default user
