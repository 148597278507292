const system = {
    basic:'基本信息',
    attachment:'上传附件',
    pass:'通过',
    reason_pass:'通过理由',
    reason_reject:'拒绝理由',
    reject:'拒绝',
    familyRegister:'居民户口本',
    certificate:'实际居住证明',
    serviceDetail:'业务办理详情',
    auditReason:'审核理由',

    
    accNum:'户号',
    accName:'户名',
    oldAccNum:'原户名',
    newAccNum:'新户名',
    waterAdd:'装表地址',
    waterUserNum:'用水人数',
    tel:'联系电话',
	phone:'联系方式',
    owerIDNum:'户主身份证号',
    reason:'申请原因',
    IDBuyer:'买方身份证',
    IDseller:'卖方身份证',
    IDCopy:'身份证复印件',
    hCertificate:'产权证明',
	dCertificate:'拆迁证明',
	statementCategory:'结算表类别',
	oldAccMeterNum:'原户表号',
	address:'地址',
	newBackName:'新户开户银行',
	newBankAcc:'账号',
	newMeterMethod:'新表结算方式',
	resolvePhone:'经办人手机号',
	caliber:'口径',
	oldBankName:'原开户银行',
	oldBankAccName:'原开户名',
	oldBankAcc:'原开户银行账号',
	newBankName:'新开户银行',
	newBankAccName:'新开户名',
	newBankAcc:'新开户银行账号',
	buildAdd:'施工地点',
	contact:'联系人',
	applyWaterNum:'意向申请水表数量',
	changeNature:'更改性质',
	changeType:'更改类型',
	changeReason:'更改理由',
	unitName:'单位名称',
	communityInfo:'小区名称地址',
	meterCaliber:'意向申报水表口径 DN',
}


export default system